$primaryFont:  "Poppins", sans-serif;
$secondaryFont: "Inter", sans-serif;
$ralewayFont: "Raleway", sans-serif;
$dmSerifDisplayFont: "DM Serif Display", sans-serif;
$bebasNeueFont: "Bebas Neue", cursive;

// $primary: #e7b521;
$primary: #FD9D0E;
$primaryVar: #e39725;
$primary3: #f9f6d7;
$secondary: #333333;
$secondary1: #3c3a3c;
$secondary2: #f0efe9;
$secondary3: #202020;
$whitePrimary: rgba(255, 255, 255, 1);
$whiteSecondary: rgba(255, 255, 255, 0.85);
$whiteSecondary2: rgba(255, 255, 255, 0.65);
$blackPrimary: #000000;
$blackSecondary: #1F1F1F;
$blackSecondary2: rgba(0, 0, 0, 0.65);
$blackSecondary3: rgba(0, 0, 0, 0.35);
$blackSecondary4: rgba(0, 0, 0, 0.18);
$grey1: #fafafa;
$grey2: #ebe9e9;
$grey3: #7d7d7d;
$grey4: #f5f5f5;
$warningMain: #ed6c02;
$warningLight: #f9f6d7;
$warningDark: #ec712c;
$red: #e70707;
