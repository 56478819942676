body {
  margin: 0;
  font-family: $primaryFont;
  background: $grey4;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
a,
p,
span,
h1,
h2,
h3,
h4,
h5,
h6,
button,
input,
b {
  // font-family: "Poppins" sans-serif;
  font-family: $primaryFont;
}
a {
  text-decoration: none;
  color: inherit;
}
a:hover {
  text-decoration: none;
  color: inherit;
}
legend {
  float: none !important;
}

.resImg {
  overflow: hidden;
}
.resImg img {
  max-width: 100%;
  vertical-align: middle;
}

//Border radius=======================================
.border-radius-1 {
  border-radius: 4px !important;
}
.border-radius-2 {
  border-radius: 8px !important;
}
.border-radius-3 {
  border-radius: 12px !important;
}
.border-radius-4 {
  border-radius: 16px !important;
}
.border-radius-5 {
  border-radius: 20px !important;
}

// =======================FONT SIZE======================
// =======================FONT  ======================
.fc-grey {
  color: #999999 !important;
}
.fc-green {
  color: #47a738 !important;
}
.fc-primary {
  color: $primary !important;
}
.fc-primary-var {
  color: $primaryVar !important;
}
.fc-secondary {
  color: $secondary !important;
}
.fc-dark-grey {
  color: $secondary3 !important;
}
.fc-light-grey {
  color: $grey4 !important;
}
.fc-red {
  color: $red !important;
}
.fc-black {
  color: $blackPrimary !important;
}
.fc-white {
  color: #ffffff !important;
}
.fc-black-secondary {
  color: $blackSecondary !important;
}

.fill-white {
  fill: #fff !important;
}
.fill-grey {
  fill: #999999 !important;
}
.fill-green {
  fill: #47a738 !important;
}
.fill-primary {
  fill: $primary !important;
}
.fill-primary-var {
  fill: $primaryVar !important;
}
.fill-dark-grey {
  fill: $secondary3 !important;
}
.fill-light-grey {
  fill: $grey4 !important;
}

.f-s-10 {
  font-size: 10px !important;
}
.f-s-12 {
  font-size: 12px !important;
}
.f-s-14 {
  font-size: 14px !important;
}
.f-s-16 {
  font-size: 16px !important;
}
.fm-poppins {
  font-family: $primaryFont !important;
}
.fm-inter {
  font-family: $secondaryFont !important;
}
.fm-rale {
  font-family: $ralewayFont !important;
}
.fm-dm-serif {
  font-family: $dmSerifDisplayFont !important;
}
.fm-bebas-neue {
  font-family: $bebasNeueFont !important;
}

.fw-m-b {
  font-weight: 500;
}
.fw-semi-b {
  font-weight: 600;
}
.fw-bold {
  font-weight: 700;
}
.heading-1 {
  font-size: 42px;
  font-family: $primaryFont;
}
.heading-2 {
  font-size: 38px;
  font-family: $primaryFont;
}
.heading-3 {
  font-size: 26px;
  font-family: $primaryFont;
}
.heading-4 {
  font-size: 22px;
  font-weight: 400;
  font-family: $primaryFont;
}
.sub-heading-1 {
  font-size: 24px;
  @extend .fw-m-b;
}
.sub-heading-2 {
  font-size: 18px;
  @extend .fw-m-b;
}
.des-1 {
  font-size: 22px;
}
.des-2 {
  font-size: 18px;
}
.card-h {
  font-size: 22px;
  @extend .fw-semi-b;
}
.card-h-2 {
  font-size: 18px;
  @extend .fw-semi-b;
}
.card-des {
  font-size: 14px;
  @extend .fw-m-b;
}
.c-text-fade {
  color: #414042;
}
.c-text-primary {
  color: $primary !important;
}
@media (max-width: 480px) {
  .taj-1 {
    font-size: 52px;
  }
  .heading-1 {
    font-size: 28px;
    font-weight: bold;
  }
  .heading-2 {
    font-size: 24px;
    font-weight: 700;
  }
  .heading-3 {
    font-size: 22px;
  }
  .sub-heading-1 {
    font-size: 18px;
    font-weight: 600;
  }
  .sub-heading-2 {
    font-size: 16px;
    font-weight: 700;
  }
  .des-1 {
    font-size: 12px;
  }
  .des-2 {
    font-size: 14px;
  }
  .card-h {
    font-size: 14px;
    font-weight: 600;
  }
  .card-h-2 {
    font-size: 12px;
    font-weight: 600;
  }

  .card-des {
    font-size: 12px;
  }
  .w-xs-100 {
    width: 100% !important;
  }
}

/* margin---------------------------------- */

.mt-175 {
  margin-top: 175px !important;
}
.mt-130 {
  margin-top: 130px !important;
}
.mt-75 {
  margin-top: 75px !important;
}
.mt-60 {
  margin-top: 60px !important;
}
.mt-40 {
  margin-top: 40px !important;
}
.mb-60 {
  margin-bottom: 60px !important;
}
/* padding--------------------------------- */
.px-40 {
  padding-left: 40px !important;
  padding-right: 40px !important;
}
.py-40 {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}
.pd-t-40 {
  padding-top: 40px !important;
}
.pd-t-80 {
  padding-top: 80px !important;
}
.pd-t-60 {
  padding-top: 60px !important;
}
.pd-t-120 {
  padding-top: 120px !important;
}
.pd-t-150 {
  padding-top: 150px !important;
}

.height-100 {
  height: 100% !important;
}
// LINKS=====================
.clear-link {
  text-decoration: none;
}
.clear-link:hover {
  text-decoration: none;
}
.pos-relative {
  position: relative !important;
}
.fade-link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.5);
  transition: all 0.4s;
}
.fade-link:hover {
  color: rgba(0, 0, 0, 1);
}
.dark-link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.8);
  transition: all 0.4s;
}
.dark-link:hover {
  color: rgba(0, 0, 0, 1);
}
.no-legend fieldset {
  legend {
    display: none;
  }
  top: 0;
}

.p-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
// OTHERS+++++++++++++++++++++++++++++++++++++++++++++++++++++
.bg-white-wm {
  background-image: url(../assets/img/static/bg/worldMapWhite.png);
  background-position: center center;
  background-repeat: no-repeat;
}
.bg-dot-link {
  background-image: url(../assets/img/static/bg/dotLink.png);
  background-position: center;
  background-repeat: repeat;
  background-size: contain;
}

.c-bg-primary {
  background-color: $primary !important;
}
.c-bg-primary-var {
  background-color: $primaryVar !important;
}
.c-bg-secondary {
  background-color: $secondary1 !important;
}
.bg-secondary-1 {
  background-color: $secondary1 !important;
}
.bg-secondary-2 {
  background-color: $secondary2 !important;
}
.bg-primary-light {
  background-color: $warningLight !important;
}
.bg-light-grey {
  background-color: $grey4 !important;
}
.bg-green-light {
  background-color: #e4ffe7 !important;
}
.bg-red {
  background-color: $red !important;
}
.bg-black {
  background-color: #000 !important;
}
.bg-green {
  background-color: #2b8b0a !important;
}
.bg-light-green {
  background-color: #d7f9da !important;
}
.flower-bg {
  background-image: url(../assets/img/static/bg/floweBg.png);
  background-repeat: repeat;
}
.b-b-red {
  position: relative;
  padding-bottom: 4px;
}
.b-b-red::after {
  position: absolute;
  content: "";
  width: 80%;
  left: 10%;
  top: 100%;
  height: 3px;
  background-color: $primaryVar;
}
@media (max-width: 480px) {
  .resImg {
    max-width: 300px !important;
  }
}
.dotted-list-items {
  list-style: none;
  li {
    align-items: flex-start;
    &::before {
      content: "\2022";
      font-weight: bold;
      display: inline-block;
      width: 1em;
      flex: 0 0 1em;
      @extend .fc-grey;
    }
  }
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.swiper-slide {
  height: initial;
}
